
#app {
    width: 100%;
    height: 100%;
    .app-wrap {
        min-height: 672px;
        background: #fff;
    }
    .search-bg {
        background: #f5f5f5;
    }
}
.f5 {
    background: #f5f5f5;
}
.ff {
    background: #fff;
}
/*.bugao {
    -webkit-filter: grayscale(100%);
-moz-filter: grayscale(100%);
-ms-filter: grayscale(100%);
-o-filter: grayscale(100%);
filter: grayscale(100%);
filter: gray; 
}*/
