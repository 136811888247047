html,
body {
    width: 100%;
    height: 100%;
}

html,
body,
p {
    padding: 0;
    margin: 0;
    // font-size: 14px;
    // color: #333;
    font-family: Microsoft YaHei;
    box-sizing: border-box;
}

button {
    cursor: pointer;
    outline: none;
}

input {
    outline: none;
}

ul,
li {
    list-style: none;
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: 400;
}

a {
    text-decoration: none;
}

.wid1200 {
    width: 1200px;
    margin: 0 auto;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.clear {
    clear: both;
}

.high-search-page {
    .el-input.is-active .el-input__inner,
    .el-input__inner:focus {
        border-color: rgba(189, 26, 45, 1) !important;
    }
    .el-button:focus,
    .el-button:hover {
        color: rgba(189, 26, 45, 1) !important;
        border-color: rgba(189, 26, 45, 0.2) !important;
        background-color: rgba(189, 26, 45, 0.2) !important;
    }
    .el-range-editor.is-active,
    .el-range-editor.is-active:hover {
        border-color: rgba(189, 26, 45, 1) !important;
    }
    .el-date-table td.today span {
        color: rgba(189, 26, 45, 1);
    }
    .el-picker-panel__icon-btn:hover {
        color: rgba(189, 26, 45, 1) !important;
    }
    .el-picker-panel__icon-btn:hover {
        color: rgba(189, 26, 45, 1) !important;
    }
    .el-date-table td.available:hover {
        color: rgba(189, 26, 45, 1) !important;
    }
    .el-date-table td.end-date span,
    .el-date-table td.start-date span {
        background-color: rgba(189, 26, 45, 1) !important;
    }
    .el-breadcrumb__inner a:hover,
    .el-breadcrumb__inner.is-link:hover {
        color: #666;
    }
}

//下拉框
.el-dropdown-menu__item {
    line-height: 40px !important;
    text-align: center !important;
    font-size: 16px !important;
    a {
        line-height: 40px !important;
        text-align: center !important;
        font-size: 16px !important;
    }
}

//分页
.pagination-wrap {
    .el-pagination {
        color: #333;
        font-size: 14px;
        display: flex;
        align-items: center;
        .el-pager li {
            color: #333;
            font-size: 14px;
            font-weight: 400;
            border: 1px solid rgba(0, 0, 0, 0.1);
            margin: 0 2px;
            padding: 0 10px;
            min-width: 40px;
            height: 37px;
            line-height: 37px;
            box-sizing: border-box;
        }
        .el-pager li:hover {
            color: #BD1A2D;
        }
        .el-pager li.active {
            color: #BD1A2D;
        }
        .el-pagination__jump {
            .el-input {
                .el-input__inner {
                    height: 37px;
                    line-height: 37px;
                    border-radius: 0;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                }
            }
        }
    }
    .el-pagination button,
    .el-pagination span:not([class*=suffix]) {
        color: #333;
        font-size: 14px;
    }
}

.el-pagination button,
.el-pagination span:not([class*=suffix]) {
    display: block;
}

.el-pagination button,
.el-pagination span:not([class*=suffix]) {
    height: 37px;
    line-height: 37px;
}


.preprint-page .preprint-main .bot-main .cooperation-left .title-preprint .pagination-wrap {
    .el-pagination button,.el-pagination span:not([class*=suffix]) {
        height: 30px;
        line-height: 30px;
    }
    .el-pagination .btn-prev {
        padding-right: 7px;
        margin-right: 9px;
        border: 1px solid #EAEFF3;
    }
    .el-pagination .btn-next {
        padding-left: 7px;
        border: 1px solid #EAEFF3;
    }
}
.oa-page .oa-main .book-wrap .com-title .pagination-wrap {
    .el-pagination button,.el-pagination span:not([class*=suffix]) {
        height: 30px;
        line-height: 30px;
    }
    .el-pagination .btn-prev {
        padding-right: 7px;
        margin-right: 9px;
        border: 1px solid #EAEFF3;
    }
    .el-pagination .btn-next {
        padding-left: 7px;
        border: 1px solid #EAEFF3;
    }
}


.header-component {
    .main-header {
        .search-wrap {
            .search-input {
                height: 50px;
                .search-border-wrap {
                    // border: 2px solid #F73E3E;
                    display: flex;
                    box-sizing: border-box;
                    // height:40px;
                    // line-height:40px;
                    // width: 700px;
                    height: 100%;
                    .search-select-wrap {
                        float: left;
                        // width: 118px;
                        width: 90px;
                        height: 100%;
                        line-height: 100%;
                        background: #fff;
                        border-right: 1px solid #e6e6e6;
                        font-weight: 400;
                        .el-select {
                            // font-weight: 700;
                            height: 100%;
                            .el-input {
                                height: 100%;
                                font-size: 14px;
                                font-weight: 400;
                            }
                            .el-input--suffix .el-input__inner {
                                padding-right: 0;
                                border-radius: 0;
                                font-family: 'yahei';
                            }
                            .el-input__inner {
                                // font-weight: 700;
                                height: 100%;
                                line-height: 100%;
                                border-radius: 0;
                                border: none;
                            }
                        }
                    }
                    .search-input-wrap {
                        float: left;
                        // flex: 1;
                        // width: 78.7%;
                        width: 480px;
                        background: #fff;
                        height: 100%;
                        line-height: 100%;
                        .el-input {
                            // font-weight: 700;
                            font-size: 14px;
                            height: 100%;
                            line-height: 100%;
                            border-radius: 0;
                            .el-input__inner {
                                // font-weight: 700;
                                height: 100%;
                                line-height: 100%;
                                border-radius: 0;
                                border: none;
                                padding: 0 6px;
                                padding-left: 10px;
                                font-weight: normal;
                            }
                        }
                    }
                }
                .el-button {
                    background-color: #BD1A2D;
                    border-color: #BD1A2D;
                    font-size: 18px;
                    border-radius: 0;
                    height: 51px;
                    margin-top: -1px;
                }
                .el-button:hover {
                    opacity: 0.9;
                }
                .el-button--warning:focus,
                .el-button--warning:hover {
                    background: #BD1A2D;
                    border-color: #BD1A2D;
                    opacity: 0.9;
                }
            }
        }
    }
}
.header-oa-component, .header-preprint-component {
    .header-oa-wrap, .header-preprint-wrap {
        .search-border-wrap {
            // border: 2px solid #F73E3E;
            display: flex;
            box-sizing: border-box;
            // height:40px;
            // line-height:40px;
            // width: 700px;
            height: 100%;
            .search-select-wrap {
                float: left;
                // width: 118px;
                width: 115px; //90px;
                height: 100%;
                line-height: 100%;
                background: #fff;
                // border-right: 1px solid #e6e6e6;
                font-weight: 400;
                border-radius: 4px 0 0 4px;
                position: relative;
                .el-select {
                    // font-weight: 700;
                    height: 100%;
                    .el-input {
                        height: 100%;
                        font-size: 14px;
                        font-weight: 400;
                        .el-input__suffix {
                            .el-input__suffix-inner {
                                
                                .el-icon-arrow-up:before {
                                    // content: "\e6e1";
                                    content: "\e78f";//"\e790";
                                    font-size: 16px;
                                    color: #b1bac0;
                                }
                            }
                        }
                    }
                    .el-input--suffix .el-input__inner {
                        padding-right: 0;
                        border-radius: 0;
                        font-family: 'yahei';
                        font-size: 16px;
                        color: #2B221B;
                    }
                    .el-input__inner {
                        // font-weight: 700;
                        height: 100%;
                        line-height: 100%;
                        border-radius: 0;
                        border: none;
                    }
                }
            }
            .search-select-wrap::after {
                content: '';
                position: absolute;
                right: 0;
                top: 20px;
                width: 1px;
                height: 44px;
                background: #EAEFF3;
            }
            .search-input-wrap {
                float: left;
                // flex: 1;
                // width: 78.7%;
                width: 774px;
                background: #fff;
                height: 100%;
                line-height: 100%;
                .el-input {
                    // font-weight: 700;
                    font-size: 14px;
                    height: 100%;
                    line-height: 100%;
                    border-radius: 0;
                    .el-input__inner {
                        // font-weight: 700;
                        height: 100%;
                        line-height: 100%;
                        border-radius: 0;
                        border: none;
                        padding: 0 6px;
                        padding-left: 10px;
                        font-weight: normal;
                        font-size: 16px;
                        color: #2B221B;
                    }
                }
            }
            .search-input-aj {
                width: 865px;
                border-radius: 4px 0 0 4px !important;
                .el-input {
                    
                    input {
                        border-radius: 4px 0 0 4px !important;
                    }
                }
            }
        }
        .el-button {
            background-color: #C30D23;
            border-color: #C30D23;
            font-size: 18px;
            border-radius: 0;
            // height: 51px;
            border-radius: 4px !important;
            margin-top: -1px;
        }
        .el-button:hover {
            opacity: 0.9;
        }
        .el-button--warning:focus,
        .el-button--warning:hover {
            background: #C30D23;
            border-color: #C30D23;
            opacity: 0.9;
        }
    }
}
.header-preprint-component {
    .header-preprint-wrap {
        .search-border-wrap {
            .search-input-wrap {
                width: 864px;
            }
        }
    }
}


//注释样式
#mjs_tip {
    font-size: 12px;
}

.tip {
    width: 345px;
    border: 1px solid #d1e4f2;
    padding: 6px 10px 10px;
    background: #fff url(https://www.lieguozhi.com/skwx_lgz/images/z_tiptitle.jpg) repeat-x;
    font-weight: bold;
    font-size: 13px;
    color: #0f598e;
}

#mjs_tip div {
    font-weight: normal;
    margin-top: 15px;
    color: #000;
    word-break: break-all;
}

#mjs_tip div .role3 {
    display: inline-block;
    height: 14px !important;
    margin-bottom: -2px;
}

//首页注释不显示
.home-page sup {
    display: none !important;
}

.bread-component sup {
    display: none !important;
}

.chart-detail-page .chart-info sup {
    display: none !important;
}

.chart-table-page sup {
    display: none !important;
}

.chart-detail-page {
    h5.chart-title {
        sup {
            font-size: 14px;
        }
    }
}

.el-dialog__wrapper {
    .el-dialog {
        .el-dialog__body {
            p {
                label {
                    .el-radio__label {
                        font-weight: 400;
                    }
                    .el-radio__input.is-checked .el-radio__inner {
                        border-color: #bd1a2d;
                        background: #bd1a2d;
                    }
                    .el-radio__input.is-checked+.el-radio__label {
                        color: #bd1a2d;
                    }
                }
            }
        }
        .el-button--mini,
        .el-button--small {
            font-size: 14px;
        }
    }
}

.el-dialog__body {
    color: #333;
}

//请登录弹框
.com-tips {
    display: block;
    color: #606266;
    text-align: center;
    font-size: 18px;
    line-height: 34px;
    padding-top: 30px;
    .com-login-tips {
        color: #bd1a2d;
        cursor: pointer;
    }
}

.el-dialog__body {
    min-height: 160px;
}

//检索结果页
.search-page {
    .right-search {
        .second-search-wrap {
            .second-search {
                .second-date {
                    .el-date-editor {
                        width: 119px !important;
                        .el-input__inner {
                            border-radius: 0;
                            border: none;
                            padding-left: 10px;
                            padding-right: 10px;
                            color: #666666;
                        }
                        input::-webkit-input-placeholder {
                            /* WebKit browsers */
                            color: #666666;
                        }
                        input:-moz-placeholder {
                            /* Mozilla Firefox 4 to 18 */
                            color: #666666;
                        }
                        input::-moz-placeholder {
                            /* Mozilla Firefox 19+ */
                            color: #666666;
                        }
                        input:-ms-input-placeholder {
                            /* Internet Explorer 10+ */
                            color: #666666;
                        }
                        .el-input__prefix {
                            display: none;
                        }
                    }
                }
            }
            ul.result-list {
                li {
                    .right-result {
                        p {
                            span {
                                p {
                                    display: inline;
                                    font-size: 16px;
                                    line-height: 17px;
                                    color: #666;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//公共标题
.com-title {
    height: 45px;
    line-height: 45px;
    border-bottom: 2px solid #dedede;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
        display: flex;
        align-items: center;
        img {
            width: 24px;
            height: 24px;
            margin-right: 11px;
            margin-left: 3px;
        }
        h5 {
            height: 45px;
            line-height: 45px;
            font-size: 24px;
            color: #000000;
            border-bottom: 3px solid #bd1a2d;
            max-width: 300px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    ul {
        display: flex;
        li {
            font-size: 18px;
            margin-right: 18px;
            cursor: pointer;
            line-height: 36px;
            color: #333;
        }
    }
    span {
        font-size: 14px;
        color: #404040;
        cursor: pointer;
        img {
            width: 4px;
            height: 8px;
            margin-left: 10px;
        }
    }
}

//轮播图：预印本、获取文本、社会学库
.com-banner-component {
    .block {
        width: 780px;
        height: 300px;
        .el-carousel {
            width: 780px;
            height: 300px;
            .el-carousel__container {
                height: 300px !important;
            }
            .el-carousel__item {
                width: 780px;
                height: 300px;
                div.swiper-img {
                    position: relative;
                    width: 780px;
                    height: 300px;
                    img {
                        width: 780px;
                        height: 300px;
                    }
                    h3 {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 780px;
                        height: 50px;
                        background: rgba(2, 2, 2, 0.5);
                        p {
                            width: 620px;
                            height: 50px;
                            line-height: 50px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            color: #fff;
                            font-size: 22px;
                            margin-left: 22px;
                        }
                    }
                }
            }
            .el-carousel__indicators {}
            .el-carousel__indicators--horizontal {
                left: auto;
                right: 19px;
                transform: none;
                .el-carousel__button {
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background: #FFFDFD;
                }
            }
            .is-active {
                .el-carousel__button {
                    background: #FCBB0B;
                }
            }
        }
    }
}
.com-banner-preprint-component {
    .block {
        width: 1200px;
        height: 320px;
        .el-carousel {
            width: 1200px;
            height: 320px;
            .el-carousel__container {
                height: 320px !important;
            }
            .el-carousel__item {
                width: 1200px;
                height: 320px;
                div.swiper-img {
                    position: relative;
                    width: 1200px;
                    height: 320px;
                    img {
                        width: 1200px;
                        height: 320px;
                    }
                    h3 {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        width: 888px;
                        height: 45px;
                        background: rgba(2, 2, 2, 0.5);
                        p {
                            width: 620px;
                            height: 45px;
                            line-height: 45px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            color: #fff;
                            font-size: 18px;
                            margin-left: 22px;
                        }
                    }
                }
            }
            .el-carousel__indicators {}
            .el-carousel__indicators--horizontal {
                left: auto;
                right: 19px;
                transform: none;
                .el-carousel__button {
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background: #FFFDFD;
                }
            }
            .is-active {
                .el-carousel__button {
                    background: #FCBB0B;
                }
            }
        }
    }
}
.com-banner-oa-component {
    .block {
        width: 690px;
        height: 350px;
        .el-carousel {
            width: 690px;
            height: 385px;
            .el-carousel__container {
                height: 350px !important;
            }
            .el-carousel__item {
                width: 690px;
                height: 350px;
                div.swiper-img {
                    position: relative;
                    width: 690px;
                    height: 350px;
                    img {
                        width: 690px;
                        height: 350px;
                    }
                    h3 {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        width: 690px;
                        height: 45px;
                        background: rgba(0, 0, 0, 0.4);
                        p {
                            width: 600px;
                            height: 45px;
                            line-height: 45px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            color: #fff;
                            font-size: 16px;
                            //margin-left: 22px;
                            box-sizing: border-box;
                            padding-left: 18px;
                            padding-right: 18px;
                        }
                    }
                }
            }
            .el-carousel__indicators {}
            .el-carousel__indicators--horizontal {
                left: 50%;
                transform: translateX(-50%);
                // right: 19px;
                transform: none;
                .el-carousel__button {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: #CFCFCF; //#FFFDFD;
                }
            }
            .is-active {
                .el-carousel__button {
                    background: #C30D23;
                }
            }
        }
    }
}

//轮播图：学者、机构
.library-expert-ins-component {
    .block {
        width: 278px;
        height: 150px;
        .el-carousel {
            width: 278px;
            height: 150px;
            .el-carousel__container {
                height: 150px !important;
                .el-carousel__arrow {
                    width: 17.6px;
                    height: 17.6px;
                    box-sizing: border-box;
                    border: 1px solid #DBDBDB;
                    display: block !important;
                }
                .el-carousel__arrow--left {
                    left: 210px;
                    top: 13px;
                }
                .el-carousel__arrow--right {
                    right: 0;
                    top: 13px;
                }
                // el-carousel__arrow el-carousel__arrow--left
            }
            .el-carousel__item {
                width: 278px;
                height: 150px;
                div.swiper-img {
                    // position: relative;
                    width: 278px;
                    height: 150px;
                    display: flex;
                    img {
                        width: 106px;
                        height: 150px;
                        cursor: pointer;
                        box-shadow: 0px 3px 7px 0px rgba(15, 11, 5, 0.29);
                    }
                    h3 {
                        // position: absolute;
                        // bottom: 0;
                        // left: 0;
                        width: 172px;
                        height: 150px;
                        div {
                            width: 200px;
                            height: 150px;
                            // overflow: hidden;
                            // text-overflow: ellipsis;
                            // white-space: nowrap;
                            color: #fff;
                            h6 {
                                color: #333;
                                font-size: 16px;
                                width: 110px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                height: 28px;
                                line-height: 28px;
                                padding-left: 11px;
                                box-sizing: border-box;
                                cursor: pointer;
                            }
                            p {
                                width: 172px;
                                line-height: 24px;
                                font-size: 14px;
                                margin-top: 4px;
                                color: #666;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 5; // 控制多行的行数
                                -webkit-box-orient: vertical;
                                padding-left: 11px;
                                box-sizing: border-box;
                            }
                        }
                    }
                    .ins-small {
                        width: 278px;
                        height: 150px;
                        div {
                            h6 {
                                width: 217px;
                                padding-left: 10px;
                                position: relative;
                            }
                            h6::before {
                                content: '';
                                width: 4px;
                                height: 4px;
                                border-radius: 50%;
                                background: #FCBB0B;
                                position: absolute;
                                left: 0;
                                top: 12px;
                            }
                            p {
                                width: 278px;
                                padding-left: 0;
                            }
                        }
                    }
                }
            }
            .el-carousel__indicators {
                display: flex;
                justify-content: space-around;
                width: 30px;
            }
            .el-carousel__indicators--horizontal {
                // left: auto;
                // right: 19px;
                // transform: none;
                left: auto;
                right: 19px;
                top: 0px;
                transform: none;
                .el-carousel__indicator--horizontal {
                    padding: 12px 0;
                }
                .el-carousel__button {
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background: #A3A3A3;
                }
            }
            .is-active {
                .el-carousel__button {
                    background: #FCBB0B;
                }
            }
        }
    }
}

//轮播图：专题详情页
.subject-detail-banner-component {
    .block {
        width: 858px;
        height: 260px;
        .el-carousel {
            width: 858px;
            height: 260px;
            .el-carousel__container {
                height: 260px !important;
            }
            .el-carousel__item {
                width: 858px;
                height: 260px;
                div.swiper-img {
                    position: relative;
                    width: 858px;
                    height: 260px;
                    img {
                        width: 858px;
                        height: 260px;
                    }
                    h3 {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 858px;
                        height: 50px;
                        background: rgba(2, 2, 2, 0.5);
                        p {
                            width: 836px;
                            height: 50px;
                            line-height: 50px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            color: #fff;
                            font-size: 22px;
                            margin-left: 22px;
                        }
                    }
                }
            }
            .el-carousel__indicators {}
            .el-carousel__indicators--horizontal {
                left: auto;
                right: 19px;
                transform: none;
                display: none;
                .el-carousel__button {
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background: #FFFDFD;
                }
            }
            .is-active {
                .el-carousel__button {
                    background: #FCBB0B;
                }
            }
        }
    }
}

.collection-detail-page {
    .author-info {
        div {
            div {
                h6 {
                    line-height: 40px;
                    font-size: 20px;
                }
                p {
                    line-height: 26px !important;
                    font-size: 14px !important;
                    p {
                        line-height: 26px !important;
                        font-size: 14px !important;
                        p {
                            line-height: 26px !important;
                            font-size: 14px !important;
                        }
                    }
                    span {
                        line-height: 26px !important;
                        font-size: 14px !important;
                    }
                }
            }
        }
    }
    .collection-detail-wrap {
        .left-collection-detail-wrap {
            .content-info {
                p {
                    line-height: 26px;
                    font-size: 16px;
                    p {
                        line-height: 26px;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

.chart-detail-page {
    .left-chart-wrap {
        .chart-main-wrap {
            p {
                img {
                    //width: 100% !important;
                    height: auto !important;
                }
            }
        }
    }
}

//图书文章  学科年份
.com-book-component,
.com-article-component {
    .subject-year-wrap {
        font-size: 18px;
        color: #333;
        .el-select {
            width: 150px;
            margin-right: 10px;
            .el-input {
                .el-input__inner {
                    border: 1px solid #E6E6E6;
                    border-radius: 0;
                    font-size: 15px;
                }
            }
        }
    }
}

.el-select-dropdown {
    width: 150px;
    font-size: 15px;
}

.el-select-dropdown__item {
    font-size: 15px;
    font-weight: 400px;
    color: #333;
}

.el-select-dropdown__item.selected {
    color: #BD1A2D;
    font-size: 15px;
    font-weight: 400;
}

//资讯列表 分页
.news-page .el-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}

.clear-fix:after {
    content: "";
    display: block;
    clear: both;
}

.guide-collected-page .el-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px auto;
}

//头部下拉框
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
    background: rgba(189, 26, 45, 0.1)
}

//头部简介字色
.banner-content {
    p {
        color: #fff;
    }
}

.news-detail-page {
    .ltCont {
        div img {
            max-width: 100%;
        }
    }
}

.inlineImg {
    // width: 16px;
    height: 16px !important;
    text-align: center;
    vertical-align: middle;
}

//集刊年会轮播图
.annual-meeting-detail-page {
    .swiper-wrap {
        .el-carousel__container {
            height: 277px;
        }
        .el-carousel__item {
            ul {
                display: flex;
                li {
                    width: 383px;
                    height: 263.5px;
                    background: #fff;
                    padding: 0 20px;
                    margin-right: 26px;
                    h6 {
                        width: 340px;
                        font-size: 20px;
                        font-weight: bold;
                        color: #2B221B;
                        line-height: 20px;
                        padding: 25px 0;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        position: relative;
                        margin-bottom: 30px;
                    }
                    h6::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 28px;
                        height: 6px;
                        background: #C30D23;
                    }
                    p {
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        color: #2B221B;
                        line-height: 18px;
                        margin-bottom: 18px;
                    }
                }
                li:last-child {
                    margin-right: 0;
                }
            }
        }
        .el-carousel__indicator.is-active button {
            background: rgba(195, 13, 35, 1);
        }
        .el-carousel__button {
            width: 16px;
            height: 16px;
            background: #FFFFFF;
            border-radius: 50%;
            opacity: 1;
        }
    }
    //分页
    .pagination-wrap {
        .el-pagination {
            color: #333;
            font-size: 14px;
            display: flex;
            align-items: center;
            .btn-prev,
            .btn-next {
                background: rgba(0, 0, 0, 0);
                // border: 1px solid rgba(255, 255, 255, 0.7);
            }
            .el-pager li {
                color: rgba(255, 255, 255, 0.7);
                font-size: 14px;
                font-weight: 400;
                background: rgba(0, 0, 0, 0);
                // border: 1px solid rgba(0, 0, 0, 0.1);
                border: none;
                margin: 0 2px;
                padding: 0 5px;
                min-width: 40px;
                height: 20px;
                line-height: 20px;
                box-sizing: border-box;
            }
            .el-pager li:hover {
                color: #fff;
            }
            .el-pager li.active {
                color: #fff;
            }
            .el-pagination__jump {
                .el-input {
                    .el-input__inner {
                        height: 20px;
                        line-height: 20px;
                        border-radius: 0;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                    }
                }
            }
        }
        .el-pagination button,
        .el-pagination span:not([class*=suffix]) {
            color: #fff;
            font-size: 14px;
        }
    }
    .el-pagination button,
    .el-pagination span:not([class*=suffix]) {
        display: block;
    }
    .el-pagination button,
    .el-pagination span:not([class*=suffix]) {
        height: 20px;
        line-height: 20px;
    }
    .el-pagination button:disabled {
        color: rgba(255, 255, 255, 0.5);
    }
}

.annual-meeting-detail-page {
    .el-dialog__wrapper {
        background: rgba(0, 0, 0, 0.4);
        .el-dialog,
        .el-dialog__body {
            background: rgba(0, 0, 0, 0);
            box-shadow: none;
            img {
                max-width: 900px;
                max-height: 600px;
                box-shadow: 0 1px 3px rgba( 0, 0, 0, 0.3);
            }
        }
        .el-dialog__header {
            padding: 0;
            .el-dialog__headerbtn {
                display: none;
            }
        }
        .el-dialog__body {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.annual-meeting-page {
    .el-dialog__wrapper {
        background: rgba(0, 0, 0, 0.4);
        .el-dialog,
        .el-dialog__body {
            background: rgba(0, 0, 0, 0);
            box-shadow: none;
            img {
                max-width: 900px;
                max-height: 600px;
                box-shadow: 0 1px 3px rgba( 0, 0, 0, 0.3);
            }
        }
        .el-dialog__header {
            padding: 0;
            .el-dialog__headerbtn {
                display: none;
            }
        }
        .el-dialog__body {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    //分页
    .pagination-wrap {
        .el-pagination {
            color: #333;
            font-size: 14px;
            display: flex;
            align-items: center;
            .btn-prev,
            .btn-next {
                background: rgba(0, 0, 0, 0);
                // border: 1px solid rgba(255, 255, 255, 0.7);
            }
            .el-pager li {
                color: rgba(255, 255, 255, 0.7);
                font-size: 14px;
                font-weight: 400;
                background: rgba(0, 0, 0, 0);
                // border: 1px solid rgba(0, 0, 0, 0.1);
                border: none;
                margin: 0 2px;
                padding: 0 5px;
                min-width: 40px;
                height: 20px;
                line-height: 20px;
                box-sizing: border-box;
            }
            .el-pager li:hover {
                color: #fff;
            }
            .el-pager li.active {
                color: #fff;
            }
            .el-pagination__jump {
                .el-input {
                    .el-input__inner {
                        height: 20px;
                        line-height: 20px;
                        border-radius: 0;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                    }
                }
            }
        }
        .el-pagination button,
        .el-pagination span:not([class*=suffix]) {
            color: #fff;
            font-size: 14px;
        }
    }
    .el-pagination button,
    .el-pagination span:not([class*=suffix]) {
        display: block;
    }
    .el-pagination button,
    .el-pagination span:not([class*=suffix]) {
        height: 20px;
        line-height: 20px;
    }
    .el-pagination button:disabled {
        color: rgba(255, 255, 255, 0.5);
    }
}

.article-detail-page {
    .article-msg {
        .article-body {
            .article-content {
                img {
                    max-width: 92%;
                }
                p {
                    img {
                        max-width: 92% !important;
                    }
                }
            }
        }
    }
}

.oa-page .introduction-paper .introduction-wrap .wid1200 .right-introduction div p p {
    color: #828282;
    font-size: 14px;
    line-height: 26px;
}

.aj-search-page {
    .cont {
        .el-row {
            .sort_box::-webkit-scrollbar {
                /* 滚动条整体部分，可以设置宽度啥的 */
                width: 4px;
            }
            .sort_box::-webkit-scrollbar-thumb {
                border-radius: 6px;
                // background: #fff;
                // linear-gradient(180deg, #dfad49 0%, #ffe5b0 118.54%);
                background: linear-gradient(180deg, #bd1a2d 0%, #f5c1c7 118.54%);
            }
            .sort_box::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.6);
                border-radius: 6px;
                background: rgba(244, 244, 244, 0.4);
            }
        }
    }
}

.aj-collected-papers {
    .info-wrap {
        .abstract {
            .abstract-msg {
                overflow-y: auto;
            }
            .abstract-msg::-webkit-scrollbar {
                /* 滚动条整体部分，可以设置宽度啥的 */
                width: 8px;
            }
            .abstract-msg::-webkit-scrollbar-thumb {
                border-radius: 6px;
                // background: #fff;
                // linear-gradient(180deg, #dfad49 0%, #ffe5b0 118.54%);
                background: linear-gradient(180deg, #D30F0F 0%, #FCB98C 118.54%);
            }
            .abstract-msg::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.6);
                border-radius: 6px;
                background: rgba(244, 244, 244, 0.4);
            }
        }
        .contributed-articles {
            .contributed-articles-msg {
                overflow-y: auto;
                // p {

                //     font-size: 16px;
                //     color: #0D1020;
                //     line-height: 20px;
                //     span {
                //         font-size: 16px;
                //         color: #0D1020;
                //         line-height: 20px;
                //     }

                // }
            }
            .contributed-articles-msg::-webkit-scrollbar {
                /* 滚动条整体部分，可以设置宽度啥的 */
                width: 8px;
            }
            .contributed-articles-msg::-webkit-scrollbar-thumb {
                border-radius: 6px;
                // background: #fff;
                // linear-gradient(180deg, #dfad49 0%, #ffe5b0 118.54%);
                background: linear-gradient(180deg, #bd1a2d 0%, #f5c1c7 118.54%);
            }
            .contributed-articles-msg::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.6);
                border-radius: 6px;
                background: rgba(244, 244, 244, 0.4);
            }
        }
    }

    .aj-collected .info-wrap ul li.abstract p.abstract-msg p {
        text-indent: 33px;
        font-size: 16px;
        font-family: MicrosoftYaHei-, MicrosoftYaHei;
        font-weight: normal;
        color: #0D1020;
        line-height: 24px;
    }
}
/*.aj-search-page {
    .el-input.is-active .el-input__inner, .el-input__inner:focus {
        border-color: #bd1a2d;
    }
}*/
.el-input.is-active .el-input__inner, .el-input__inner:focus {
    border-color: #bd1a2d;
}
        