
.footer-page {
    width: 100%;
    background: #fff;
    .recommend-wrap {
        margin-top: 30px;
    }
    .recommend-link {

        h5 {
            text-align: center;
            position: relative;
            z-index: 0;
            display: flex;
            justify-content: center;
            margin-bottom: 22px;
            span {
                display: block;
                width: 100px;
                text-align: center;
                color: #2B221B;
                font-size: 18px;
                line-height: 18px;
                z-index: 2;
                background: #fff;
                font-weight: bold;
            }
        }
        h5::after {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            height: 1px;
            background: #EAEFF3;
            z-index: -1;
        }
        div {
            margin-top: 30px;
            ul {
                display: flex;
                flex-wrap: wrap;

                li {
                    margin: 0 32px 16px 0;
                    a {
                        display: block;
                        font-size: 14px;
                        color: #777777;
                        line-height: 14px;
                    }
                }
            }
        }


    }
    .top-footer-wrap {
        margin-top: 31px;
        background: #bd1a2d;
        /*height: 190px;*/
        box-sizing: border-box;
        padding: 19px 0;
        .top-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left-top-footer {
                width: 892px;
                box-sizing: border-box;
                padding: 48px 32px 44px 41px;

                p {
                    text-align: left;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #dfe0e6;
                    line-height: 30px;
                }
            }
            .right-top-footer {
                width: 298px;
                height: 152px;
                display: flex;
                box-sizing: border-box;
                justify-content: space-between;
                padding: 13px 20px 0 25px;
                div {
                    img {
                        width: 106px;
                        height: 105px;
                    }
                    p {
                        line-height: 26px;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #dfe0e6;
                        text-align: center;
                    }
                }
            }
            .com-footer {
                background: #a91728;
            }
        }
    }
    .bot-footer-wrap {
        background: #dabb85;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #010101;
        line-height: 50px;
    }
}
