
.annual-wrap {
  width: 100%;
  /*height: 250px;*/
  margin-bottom: -10px;

  img {
    width: 100%;
    height: auto;
  }
}

.header-component {
  min-width: 1400px;
  // min-height: 362px;
  min-height: 312px;
  background: url(../assets/img/headerbg.jpg);
  background-size: 100% 100%;
  position: relative;

  .top-header {
    width: 1200px;
    margin: 0 auto 36px;
    line-height: 34px;
    display: flex;
    justify-content: flex-start;
    justify-content: space-between;

    .btn-header {
      color: #cd0600;

      .login {
        margin-left: 22px;
      }

      span {
        cursor: pointer;
      }
    }

    .nav-fr .name {
      display: inline-block;
      margin-left: 10px;
      color: #cd0600;
    }

    .nav-fr .name:before {
      margin-top: 15px;
      content: '';
      float: left;
      width: 26px;
      height: 26px;
    }

    .nav-fr .name.person.personshort {
      width: 130px;
    }

    .nav-fr .name.person {
      // width: 255px;
    }

    // .nav-fr .name.person:before {
    //     background: url('../assets/img/icon_user.png') no-repeat;
    //     background-size: cover;
    // }
    .nav-fr .name.book:before {
      background: url('../assets/img/icon_book.png') no-repeat;
      background-size: cover;
    }

    .nav-fr .name a {
      margin-right: 0;
      font-weight: 500;
      color: #cd0600;
    }

    .name .login_btn {
      display: flex;

      a.login-a {
        display: block;
        max-width: 220px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .name span {
      margin: 0 5px;
    }

    .welcome-header {
      color: #404040;

      span {
        color: #cd0600;
        cursor: pointer;
      }
    }

    .top-right-header {
      span {
        cursor: pointer;
      }
    }
  }

  .main-header {
    .logo-header-wrap {
      padding-top: 4px;

      img.logo-header {
        width: 361px;
        height: 104px;
      }
    }

    .search-wrap {
      margin-left: 69px;

      ul {
        display: flex;
        margin-bottom: 10px;

        li {
          height: 28px;
          padding: 0 16px;
          line-height: 28px;
          margin-right: 14px;
          color: #292929;
          cursor: pointer;
        }

        li:hover {
        }

        .active-header {
          color: #fff;
          background: #bd1a2d;
        }
      }

      .search-ul {
        // li {

        // }
        .active-source {
          color: #fff;
          background: #bd1a2d;
        }
      }

      .search-input {
        display: flex;

        .search-border-wrap {
          float: left;
          color: #fff;
        }

        .search-icon {
          font-size: 26px;
          line-height: 0.46rem;
          color: #fff;
          cursor: pointer;
        }

        .search-btn {
          padding: 0 18px;
          border-radius: 0%;
          float: right;
          color: #fff;
          font-weight: 700;
        }

        .high-search {
          margin-left: 15px;
          font-size: 17px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 54px;
          cursor: pointer;
        }
      }

      .hot-words {
        margin-top: 12px;
        height: 15px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #404040;

        span {
          margin-right: 20px;
          cursor: pointer;
        }
      }
    }
  }

  .count-header-wrap {
    display: flex;
    justify-content: center;
    margin-top: 43px;

    li {
      background: #fffdf8;
      line-height: 36px;
      min-width: 124px;
      text-align: center;
      color: #404040;
      margin-right: 92px;

      span {
        color: #cd0600;
      }
    }

    li:last-child {
      margin-right: 0;
    }
  }

  .bot-header-wrap {
    line-height: 50px;
    width: 100%;
    background: rgba(189, 26, 45, 0.84);
    position: absolute;
    bottom: 0;
    left: 0;

    ul.bot-header {
      display: flex;

      .all-type {
        background: url(../assets/img/navicon.png);
        background-size: 100% 100%;
      }

      li {
        padding: 0 41px;
        display: flex;
        align-items: center;

        font-size: 21px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #fefefe;
        cursor: pointer;

        img {
          width: 18px;
          height: 18px;
          margin-right: 9px;
        }

        a {
          color: #fff;
        }
      }

      li:nth-child(3) {
        padding: 0 34px;
      }

      li:nth-child(4) {
        padding-left: 30px;
      }

      li:first-child {
        padding: 0 70px;
      }

      li:nth-child(2) {
        padding-left: 40px;
        padding-right: 30px;
      }

      li:last-child {
        padding-right: 0px;
      }
    }
  }
}
